/**
 * Spacings
 * ========
 * |– remove-ends-horizontal-margin
 * |– remove-ends-vertical-margin
 */

%remove-ends-horizontal-margin {
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

%remove-ends-vertical-margin {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
