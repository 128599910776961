@media (min-width: 768px) {
  .artworks-multiple {
    &__item {
      padding: var(--gutter) 0;
    }

    .split {
      &__content {
        justify-content: center !important;
      }
    }

    &--unified & {
      &__item {
        /* stylelint-disable max-nesting-depth */
        &:nth-child(odd) {
          .split {
            &__wrapper {
              justify-content: flex-end !important;
            }

            &__content {
              align-items: flex-end !important;
              order: 0 !important;
              padding: 0 var(--gutter) 0 !important;
            }

            &__text {
              text-align: right !important;
            }

            &__media {
              order: 1 !important;
            }
          }
        }

        &:nth-child(even) {
          .split {
            &__wrapper {
              justify-content: flex-start !important;
            }

            &__content {
              order: 1 !important;
              padding: 0 0 0 var(--gutter) !important;
            }

            &__media {
              order: 0 !important;
            }
          }
        }
        /* stylelint-enable */
      }
    }
  }
}

@media (min-width: 992px) {
  .artworks-multiple {
    .split {
      &__media {
        &--portrait {
          flex: 0 0 50%;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .artworks-multiple {
    .split {
      &__media {
        &--landscape {
          flex: 0 0 50%;
        }
      }
    }
  }
}
