.metas {
  > * {
    @extend %remove-ends-vertical-margin;
  }

  a {
    @extend %href;
  }

  svg {
    height: 1em;

    > * {
      fill: currentColor;
    }
  }

  [itemprop='name'] {
    white-space: nowrap;
  }

  &__row {
    display: flex;
    width: 100%;
  }

  &__cell {
    flex: 0 1 auto;

    &--label {
      flex: 0 0 auto;
      font-size: 0.75em;
      font-weight: 700;
      letter-spacing: 0.75px;
      margin: 0.35em 0 0;
      text-transform: uppercase;
    }
  }

  &__social {
    padding-right: 0.15em;

    &::before {
      display: none;
    }
  }
}
