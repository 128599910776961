/**
 * variables
 * =========
 */

$duration: 0.3s;
$container-width: 1200px;

$palettes: (
  black: (
    base: #000,
  ),
  grey: (
    base: #7f7f7f,
  ),
  white: (
    base: #fff,
  ),
);

$fonts: (
  sans-serif: #{ helvetica, arial, sans-serif },
  serif: #{ 'Roboto Slab', serif },
  alt: #{ 'Roboto Mono', helvetica, arial, sans-serif },
);

:root {
  --main: calc(100vh - 4 * var(--gutter) - var(--header) - var(--footer) );
  --gutter: 20px;
  --header: 93px;
  --footer: 149px;
}

@include media-breakpoint-up(sm) {
  :root {
    --header: 65px;
    --footer: 120px;
  }
}

@include media-breakpoint-up(md) {
  :root {
    --gutter: 30px;
    --header: 65px;
    --footer: 65px;
  }
}

@include media-breakpoint-up(lg) {
  :root {
    --gutter: 40px;
  }
}
