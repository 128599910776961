.card {
  background-color: var(--highlight);
  color: #fff;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;

  &__media {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    img {
      display: block;
      filter: grayscale(0%);
      height: 100%;
      margin: 0;
      object-fit: cover;
      object-position: 50% 0;
      padding: 0;
      transition: filter $duration * 2;
      width: 100%;
    }
  }

  &__title {
    left: 0;
    padding: 2px 10px;
    position: absolute;
    top: 100%;
    transform: translateY(0);
    transition: transform $duration;
    width: 100%;
    z-index: 1;

    &::before {
      background: var(--highlight);
      content: '';
      height: 100px;
      left: 0;
      opacity: 0.7;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }

  &__overlay {
    background-color: var(--highlight);
    display: block;
    height: 100%;
    left: 0;
    mix-blend-mode: color;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity $duration * 2;
    width: 100%;
  }

  &:hover {
    .card {
      &__media {
        img {
          filter: grayscale(100%);
        }
      }

      &__title {
        transform: translateY(-100%);
      }

      &__overlay {
        opacity: 0.5;
      }
    }
  }
}
