html,
body {
  background-color: var(--background);
  color: var(--text);
  font-family: Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
}

html {
  font: 300 10px/1.4 Helvetica, Arial, sans-serif;
}

body {
  font-size: 1.6rem; // 14px
  line-height: 1.8;
}

::selection {
  background: var(--highlight);
  color: var(--background);
}

main {
  position: relative;

  .section {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

p,
ol,
ul {
  margin: 0.5em 0;
}

.sr-only {
  @extend %sr-only;
}
