.split {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: var(--gutter);
  }

  &__content,
  &__media {
    flex: 0 0 100%;
  }

  &__content {
    order: 1;

    > * {
      @extend %remove-ends-vertical-margin;
    }
  }

  &__title {
    @extend %h1;
  }

  h2 {
    @extend %h2;
  }

  ul {
    @extend %list;
  }

  &__text {
    column-gap: var(--gutter);
    margin: 20px 0;

    a {
      @extend %href;
    }

    > * {
      @extend %remove-ends-vertical-margin;
    }
  }

  &__media {
    order: 0;
    position: relative;

    img {
      display: block;
      height: auto;
      margin: 0 calc(var(--gutter) * -1) var(--gutter);
      max-width: calc(100% + var(--gutter) * 2);
    }

    &--portrait {
      flex: 0 0 80%;
    }
  }

  &__picture {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .split {
    &__wrapper {
      flex-wrap: nowrap;
      justify-content: flex-end;
      padding-bottom: 0;
    }

    &__content {
      display: flex;
      flex: 0 1 auto;
      flex-direction: column;
      order: 0;
      padding: 0 var(--gutter) 0 0;

      &--vertical-center {
        justify-content: center;
      }

      &--vertical-bottom {
        justify-content: flex-end;
      }

      &--horizontal-center {
        align-items: center;

        .split__text {
          text-align: center;
        }
      }

      &--horizontal-right {
        align-items: flex-end;

        .split__text {
          text-align: right;
        }
      }
    }

    &__title,
    h2,
    &__text {
      margin: 10px 0;
    }

    .metas {
      margin: 10px 0 0;
    }

    &__media {
      flex: 0 0 50%;
      order: 1;

      img {
        height: auto;
        margin: 0 auto;
        max-width: 100%;
        width: auto;
      }
    }

    &--reversed & {
      &__wrapper {
        justify-content: flex-start;
      }

      &__content {
        order: 1;
        padding: 0 0 0 var(--gutter);
      }

      &__media {
        order: 0;
      }
    }
  }
}

/* stylelint-disable */
@media (min-width: 992px) {
  .split {
    &__media {
      &--portrait {
        flex: 0 0 33.333333%;

        + .split__content {
          .split__text {
            &--multi-column {
              column-count: 2;
            }
          }
        }
      }
    }
  }
}
/* stylelint-enable */

@media (min-width: 1200px) {
  .split {
    &__media {
      &--landscape {
        flex: 0 0 66.666666%;
      }
    }
  }
}
