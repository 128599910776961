.section {
  display: block;
  margin: var(--gutter) 0;
  overflow: hidden;
  position: relative;
  width: 100%;

  // elements

  &__title {
    display: block;
    font: normal 500 1.6rem/1 font(sans-serif);
    margin: 20px 0 30px;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;

    &:hover {
      .section__anchor {
        opacity: 1;
      }
    }
  }

  &--no-margin {
    margin-bottom: 0;
    margin-top: 0;
  }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  .section {
    &__cells {
      display: flex;
    }
  }
}
