.site-footer {
  width: 100%;

  a {
    @extend %href;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__nav,
  &__lang {
    align-content: flex-end;
    flex: 0 0 100%;
    flex-wrap: wrap;

    > * {
      @extend %remove-ends-vertical-margin;
    }
  }

  &__nav {
    padding: var(--gutter, 20px) 0;
  }

  &__lang {
    display: flex;
    justify-content: center;
  }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  .site-footer {
    padding: var(--gutter, 20px) 0;

    &__nav {
      flex: 1 0 auto;
      padding: 0;
    }

    &__lang {
      flex: 0 0 auto;
      justify-content: flex-end;
    }
  }
}
