.site-logo {
  color: var(--highlight);
  display: flex;
  flex: 0 0 100%;
  font-weight: bold;
  justify-content: center;
  margin: 0.5em 0;
  text-decoration: none;
}

@include media-breakpoint-up(sm) {
  .site-logo {
    flex: 0 0 auto;
  }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  .site-logo {
    display: inline-block;
  }
}
