.swiper {
  opacity: 0;
  transition: opacity $duration;

  &-container {
    --height: var(--main);

    height: var(--main);
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
    padding-bottom: 12px;
    width: 100%;
  }

  &-wrapper {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &-slide {
    opacity: 0;
    position: relative;
    transform: translateY(-10px);

    @for $i from 1 through 40 {
      &:nth-child(#{$i}) {
        transition: opacity 1s #{$i * 0.2 - 0.2}s, transform 1s #{$i * 0.2 - 0.2}s;
      }
    }
  }

  .card {
    height: 100%;
    position: relative;
    width: 100%;
  }

  &__picture {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;

    img {
      display: block;
      height: auto;
      max-height: 100%;
      position: relative;
      width: auto;
    }
  }

  &--hub,
  &--hub-landscape {
    .swiper {
      &-slide {
        height: calc(50% - 10px);
      }
    }
  }

  &--hub-square {
    .swiper {
      &-slide {
        height: calc(50% - 10px);
        width: calc(var(--main) / 2 - 10px);
      }
    }
  }

  &--hub-automatic {
    .swiper {
      &-slide {
        height: 100%;
        width: auto;
      }
    }

    .card__media {
      align-items: center;
      display: flex;
      height: 100%;
      position: relative;

      img {
        display: block;
        height: auto;
        max-height: 100%;
        position: relative;
        width: auto;
      }
    }
  }

  &--gallery {
    .swiper {
      &-slide {
        height: 100%;
        width: auto;
      }
    }
  }

  &-scrollbar {
    background: transparent;

    .swiper-container-horizontal > & {
      bottom: 0;
      height: 4px;
      left: 0;
      width: 100%;
    }

    &-drag {
      background-color: var(--highlight);
      border-radius: 0;
    }
  }

  &.is-ready {
    opacity: 1;

    .swiper {
      &-slide {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
