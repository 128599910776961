.site-header {
  padding: var(--gutter, 20px) 0;
  width: 100%;

  &__container {
    display: flex;
    flex-wrap: wrap;

    > * {
      @extend %remove-ends-vertical-margin;
    }
  }

  &__menu {
    flex: 0 0 100%;

    a {
      @extend %href;
    }
  }
}

@include media-breakpoint-up(md) {
  .site-header {
    &__container {
      align-items: flex-end;
    }
  }
}
