.textblock {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;

    > * {
      @extend %remove-ends-vertical-margin;
    }
  }

  &__content {
    flex: 0 1 auto;
    order: 1;

    > * {
      @extend %remove-ends-vertical-margin;
    }
  }

  &__title {
    @extend %h1;
  }

  h2 {
    @extend %h2;
  }

  ul {
    @extend %list;
  }

  &__text {
    margin: 20px 0;

    a {
      @extend %href;
    }

    > * {
      @extend %remove-ends-vertical-margin;
    }
  }

  .metas {
    flex: 0 0 100%;
    order: 1;
    padding: var(--gutter) 0 0;
  }
}

@media (min-width: 768px) {
  .textblock {
    &__wrapper {
      flex-wrap: nowrap;
    }

    h2 {
      column-span: all;
    }

    .metas {
      flex: 0 0 46%;
    }

    &__text {
      &--multi-column {
        column-count: 2;
      }
    }

    &--metas-left {
      .metas {
        order: 0;
        padding: 0 var(--gutter) 0 0;
      }

      &__text {
        &--multi-column {
          column-count: 1;
        }
      }
    }

    &--metas-right {
      .metas {
        order: 1;
        padding: 0 0 0 var(--gutter);
      }

      &__text {
        &--multi-column {
          column-count: 1;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .textblock {
    &__text {
      &--multi-column {
        column-count: 2;
      }
    }

    .metas {
      flex: 0 0 33.333%;
    }
  }
}
