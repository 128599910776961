.popup {
  align-items: center;
  cursor: url('../img/close-icon.png'), auto;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.5s;
  width: 100%;
  z-index: 5;

  &::before {
    background-color: var(--highlight);
    content: '';
    height: 100vh;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100vw;
  }

  &__wrapper {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25);
    opacity: 0;
    position: relative;
    transform: scale(0.85);
    transition: opacity 0.5s 0s, transform 0.5s 0s;
  }

  &--image {
    .popup {
      &__wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }

    img {
      display: block;
      height: auto;
      max-height: calc(100vh - var(--gutter) * 2);
      max-width: calc(100vw - var(--gutter) * 2);
      width: auto;
    }
  }

  &.is-active {
    opacity: 1;
    transition: opacity 0.75s;

    .popup {
      &__wrapper {
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.5s 0.25s, transform 0.5s 0.25s;
      }
    }
  }
}

.js-popup-image {
  cursor: zoom-in;
}
