/**
 * Accessibility
 * =============
 * |– sr-only
 * |– sr-only-focusable
 */

%sr-only,
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

%sr-only-focusable {
  &:active,
  &:focus {
    clip: auto;
    height: auto;
    overflow: visible;
    position: static;
    white-space: normal;
    width: auto;
  }
}
