.container {
  max-width: map-get($grid-breakpoints, xl);
  padding: 0 var(--gutter, 20px);

  &--small {
    max-width: map-get($grid-breakpoints, md);
  }
}

.container-fluid {
  padding: 0 var(--gutter, 20px);
}
